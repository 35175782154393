"use client";

import { useEffect } from "react";
import Image from "next/image";
import classnames from "classnames";

import styles from "./page.module.scss";

import { socialMedia } from "@/app/data/const/social";

const img = "/assets/img/promo01-edit.png";
const bg = "/assets/img/bg-jj.jpg";

export default function LandingPage() {

    return (
        <div className={styles.landing}>
            <div className={styles.content}>
                <div className={styles.presentation}>
                    <div className={styles.social}>
                        <div className={styles.socialWrapper}>
                            {socialMedia.map((social) =>
                                {
                                   if(!social.active){
                                        return null
                                    }
                                    return <a
                                    key={social.name}
                                    href={social.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classnames(styles.socialItem, {
                                        [styles.remove]: !social.active
                                    } )}
                                >
                                    {social.icon}
                                </a>
                                }



                            )}
                        </div>
                    </div>
                    <div className={styles.imageWrapper}>
                        <Image src={img} alt="Eddy Russo" fill  objectFit="contain" />
                    </div>

                </div>
            </div>

            <Image src={bg} alt="Eddy Russo BG" fill objectFit="cover" className={styles.background}/>

        </div>
    );
}
